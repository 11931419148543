import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import '../styles/post.css'
import { GlobalStateContext } from '../context/GlobalContextProvider'
import List from '../components/List'
import HeroImage from '../components/HeroImage/HeroImage'
import richTextOptions from '../shared/richTextOptions'
import { getContentfulUrl } from '../shared/utilities'

const Training = ({ pageContext, location }) => {
  const path = location.pathname
  const { id, title, shortName, profileImage, heroImage, description } = pageContext

  const imgQuery = '?w=700&h=300'
  const imgUrl = getContentfulUrl(profileImage) + imgQuery
  const heroImageUrl = getContentfulUrl(heroImage)

  const filterEvents = (events, filterName) => {
    return events.filter(event => event.title.indexOf(filterName) >= 0)
  }

  const eventsContext = useContext(GlobalStateContext)
  const eventsList = path.includes('events') ? filterEvents(eventsContext.events, shortName) : []

  return (
    <Layout editPageId={id} path={location.pathname}>
      <SEO title={title} />
      <HeroImage src={heroImageUrl} alt={title} title={title} isLargeImage />
      <div className="container training text-content">
        <Link to="/events/training" className="back-link">
          &larr; Training
        </Link>
        <br />
        <img src={imgUrl} alt={title} />
        {documentToReactComponents(description.json, richTextOptions)}
        {eventsList.length > 0 && (
          <>
            <h2>Upcoming Training</h2>
            <List
              data={eventsList}
              itemType="events"
              styleItem="events-preview"
              className="items-container"
            />
          </>
        )}
      </div>
    </Layout>
  )
}

export default Training

Training.propTypes = {
  pageContext: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired
}
